import { useState, useEffect } from "react";
import "./slider.sass";

const sliderData = [
  {
    image: "/assets/slider/slider1.jpg",
    heading: "Slide One",
    desc: "Helping Public Sector Leaders, Managers, Supervisors, And Employees Build Excellence In Their Organizations And In The Services They Provide."
  },
  {
    image: "/assets/slider/slider2.jpg",
    heading: "Slide Two",
    desc: "Helping Public Sector Leaders, Managers, Supervisors, And Employees Build Excellence In Their Organizations And In The Services They Provide."
  },
  {
    image: "/assets/slider/slider3.jpg",
    heading: "Slide Two",
    desc: "Helping Public Sector Leaders, Managers, Supervisors, And Employees Build Excellence In Their Organizations And In The Services They Provide."
  },
  {
    image: "/assets/slider/slider4.jpg",
    heading: "Slide One",
    desc: "Helping Public Sector Leaders, Managers, Supervisors, And Employees Build Excellence In Their Organizations And In The Services They Provide."
  },
  {
    image: "/assets/slider/slider5.jpg",
    heading: "Slide Two",
    desc: "Helping Public Sector Leaders, Managers, Supervisors, And Employees Build Excellence In Their Organizations And In The Services They Provide."
  }
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 8000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
     // eslint-disable-next-line
  }, [currentSlide]);

  return (
    <div className="slider">
      <div className="slider__prev slider__controls" onClick={prevSlide}></div>
      <div className="slider__next slider__controls" onClick={nextSlide}></div>
      {sliderData.map((slide, index) => {
        return (
          <div
            className={index === currentSlide ? "slider__slide slider__slide--current" : "slider__slide"}
            key={index}
          >
            {index === currentSlide && (
              <div>
                <img src={slide.image} alt="slide" className="slider__img" />
                <div className="slider__content">
                  {/* <h2>{slide.heading}</h2> */}
                  <p>{slide.desc}</p>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Slider;
