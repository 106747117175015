import { Link } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import Slider from '../Slider'
import logo from '../../assets/images/logo.png';
import jpea from '../../assets/images/jpea.png';
import gofar from '../../assets/images/gofar.png';



import './home.sass'

const Home = () => {
  

   
    return (

        <main className='home'>
            <Header />
            <Slider />
            <section className='services'>
                <div className='container'>
                    <div className='services__container'>
                        <Link to='/contact' className="services__item services__item--assessments" title="Learn more about comprehensive service assessment">
                            <div className="services__content">
                                <h3 className="services__title">Assessment</h3>
                                <p>We use surveys, interviews, and observation to conduct a comprehensive service assessment.</p>
                            </div>
                        </Link>
                        <Link to='/about-us' className="services__item services__item--restructuring" title="Learn more about Public Service Excellence">
                            <div className="services__content">
                                <h3 className="services__title">Planning</h3>
                                <p>We identify training, and processes to take your team to the next level. </p>
                            </div>
                        </Link>
                        <Link to='/public-workshops' className="services__item services__item--interim" title="Learn more about workshops services">
                            <div className="services__content">
                                <h3 className="services__title">Workshops</h3>
                                <p>We design workshops to address issues specific to your organization and the public sector.  </p>
                            </div>
                        </Link>
                        <Link to='/contact' className="services__item services__item--performance" title="Learn more about personalized support for leaders within the public sector">
                            <div className="services__content">
                                <h3 className="services__title">Coaching</h3>
                                <p>We provide personalized support for leaders within the public sector. </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
          
            <section className='main'>
                    <div className='container'>
                                <img src={logo} alt="PUBLIC SECTOR EXCELLENCE" className='img__inline' />
                                <p className='text__inline'>A training and development partnership that specializes in helping Public Sector leaders, managers, supervisors and employees build excellence in their organizations and in the services that they provide.</p>

                        <div className='grid grid--col2 grid--centered grid--space'>
                            <div className='grid__col'>
                                <a href='https://jpea.com/' target="_blank" rel="noreferrer">
                                    <img src={jpea} alt="JPEA" className='grid__img' />
                                </a>
                                <p>Visit the JPEA website to learn more about JPEA assessment.</p>
                            </div>
                            <div className='grid__col'>
                                <a href='https://thegofarcenter.com/' target="_blank" rel="noreferrer">
                                    <img src={gofar} alt="Go Far" className='grid__img' />
                                </a>
                                <p>Visit the GoFar website to learn more about GoFar assessment.</p>
                            </div>
                        </div>

                    </div>
            </section>
            <Footer />
        </main>

    )
}

export default Home