import Header from '../Header'
import Footer from '../Footer'



import './inhouseworkshops.sass'

const InHouseWorkshops = () => {

   
    return (

        <main className='inhouseworkshops'>
            <Header />
            <div className='container'>
                <h1 className='title'>
                    Our In-House Programs Customized to Meet Your Unique Needs
                </h1>
                <ul className='list lis--unordered'>
                    <li>Customer Service in the Public Sector - <i>Staying Curteous Under Pressure</i></li>
                    <li>Preparing for Supervision</li>
                    <li>Supervision - <i>The Academy</i></li>
                    <li>Management - <i>The Academy</i></li>
                    <li>Leadership - <i>The Academy</i></li>
                    <li>Improving Employee Performance - <i>Dealing with Unacceptable Employee Behavior</i></li>
                    <li>Building High Performance Work Teams</li>
                    <li>Public Service Ethics</li>
                    <li>Building A Workplace of Mutual Respect</li>
                    <li>Leadership At Your Level: Being a Great Employee and Co-Worker</li>
                    <li>Business Not as Normal - <i>Our Public Image: the Leader's Role</i></li>
                    <li>Conducting Effective Performance Evaluations & Appraisals</li>
                    <li>Managing Your Work Life, Time, & Priorities</li>
                    <li>Self-Managed Career Development</li>
                    <li>Preventing Harassment</li>
                    <li>Coping with Negative People</li>
                    <li>Strategic Planning: Building Mission, Vision, and Values</li>
                    <li>Deliver the Winning Interview - <i>Stand Out In A Crowd</i></li>
                    <li>Selecting Winners - <i>Interviewing & Hiring The Best</i></li>
                    <li>Managing Your Energy - <i>Going the Distance at Work and in Life Creating a Future of Your Choice</i></li>
                    <li>Managing Conflict & Improving Interpersonal Relationships in the Workplace</li>
                    <li>Managing Change</li>
                    <li>Making Effective Presentations - to the Boss, to the Board, to the Public</li>
                </ul>
            </div>
            <Footer />
        </main>

    )
}

export default InHouseWorkshops