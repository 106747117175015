import Header from '../Header'
import Footer from '../Footer'



import './ourclients.sass'

const OurClients = () => {
    
    return (

        <main className='ourclients'>

            <Header />   
            <div className='container'>
                <h1 className='title'>
                   About Public Sector Excellence </h1>
                <p>Public Sector Excellence is an organizational development and training firm founded in 1991 that specializes in helping Public Sector leaders, managers, supervisors, and employees build excellence in their organizations and in the services they provide. To help address the unique Opportunities and constraints that public sector organizations face, the firm employs consultants and trainers who understand the public sector and have experience working with local, state and federal government, school districts, courts, educational institutions, boards, commissions, and other public organizations.</p>


                <h3 className='title title--sub'>
                   Government Client Experience </h3>
                <h5 className='title title--sm'>
                    Public Sector Excellence Client and Workshop Participant Organizations have includes:
                </h5>

                <div className='grid grid--col2 grid--space'>
                    <div className='grid__col'>
                        <ul className='list'>
                            <li>City of Santa Monica</li>
                            <li>City of Stockton</li>
                            <li>California Joint Powers Insurance Authority</li>
                            <li>Sanitations Districts of Orange County</li>
                            <li>County of Los Angeles Social Services</li>
                            <li>Port of Long Beach</li>
                            <li>City of Ventura</li>
                            <li>Learn4Life Charter School</li>
                            <li>The State Bar of California</li>
                            <li>Santa Ana College</li>
                            <li>California Assoc. of School Business Officals</li>
                            <li>City of Manhattan Beach</li>
                            <li>City of Huntington Beach</li>
                            <li>Internal revenue Service</li>
                            <li>County of Los Angeles Internal Services</li>
                            <li>City of Thousand Oaks</li>
                            <li>Calif. Assoc. of County Clerks and Recorders</li>
                            <li>City of Avalon</li>
                            <li>Family and Children Together (FACT)</li>
                            <li>City of Solvang</li>
                            <li>Port of Oakland</li>
                            <li>Ukiah Unified School District</li>
                            <li>City of Palo Alto</li>
                            <li>City of Fountain Valley</li>
                        </ul>
                    </div>
                    <div className='grid__col'>
                        <ul className='list'>
                            <li>City of Long Beach</li>
                            <li>United States Navy</li>
                            <li>County of Orange</li>
                            <li>County of San Louis Obispo EOC</li>
                            <li>County of Medocino</li>
                            <li>City of Downey</li>
                            <li>County of Napa</li>
                            <li>California Judicial Council</li>
                            <li>City of Galt</li>
                            <li>County of Sonoma</li>
                            <li>County of Kern</li>
                            <li>City of Fresno</li>
                            <li>City of Beverly Hills</li>
                            <li>City of Paramount</li>
                            <li>The Cambodian Family</li>
                            <li>City of Modesto</li>
                            <li>City of Whittier</li>
                            <li>City of Chino</li>
                            <li>Newport-Mesa Unified School District</li>
                            <li>City of Grand Terrace</li>
                            <li>Berkeley Unified School District</li>
                            <li>California Assessor's Association</li>
                            <li>County of Orange Superior Court</li>
                            <li>Lake County Superior Court</li>
                        </ul>
                    </div>
                </div>

                <h5 className='title title--sm'>
                    Faculty Affiliations
                </h5>
                <div className='grid grid--col2 grid--space'>
                    <div className='grid__col'>
                        <ul className='list'><li>Rancho Santiago Community College District - Santa Ana</li></ul>
                    </div>
                    <div className='grid__col'>
                    </div>
                </div>




            </div>         
            <Footer />
        </main>

    )
}

export default OurClients