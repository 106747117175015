import Header from '../Header'
import Footer from '../Footer'


const Error404 = () => {
    
   
    return (

        <main className='error404'>
        <meta http-equiv="refresh" content="6;url=https://www.publicsector-excellence.com/" />

            <Header />

            <div className='container'>

                <h1 className='title'>Uh Oh </h1>
                <h3>Looks like you found a dead link, lets try to fix that in 3, 2, 1.....</h3>

            </div>
        
            <Footer />
        </main>

    )
}

export default Error404