
import Header from '../Header'
import Footer from '../Footer'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import './contact.sass'

const Contact = () => {

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm(
            'service_pubsecex1!',
            'template_96dtwdc',
            form.current,
            '8puJqd-QMNhT0mN_F'
        ).then(
            () => {
                //success handler - need to hide form and show success message
                alert('success')
                window.location.reload(false)
            },
            () => {
                //fail handler - need to stylize form for error handling
                alert('fail')
                window.location.reload(false)
            }
        )
    }

    return (

        <main className='contact'>
            <Header />
            <section className='container'>
                <main>
                    <h2 className='title'>Contact Us</h2>
                    <p>Thank you for your interest in Public Sector Excellence. Please fill out the form below if you have any questions or need more information.</p>
                    <div className='contact__container'>
                        <form className='form' ref={form} onSubmit={sendEmail}>
                            <input type='hidden' name='subject' val='Public Sector Excellence Contact Form' maxLength="80" />
                            <label className='form__label form__label-name'>
                                Name
                                <input placeholder='Name' type='text' name='name' className='form__input' maxLength="80" required />
                            </label>
                            <label className='form__label form__label-company'>
                                Company
                                <input placeholder='Company' type='text' name='company'  maxLength="80" className='form__input' required />
                            </label>
                            <label className='form__label form__label-phone'>
                                Phone
                                <input placeholder='Phone' type='tel' name='phone'  maxLength="15" className='form__input' required />
                            </label>
                            <label className='form__label form__label-email'>
                                Email
                                <input placeholder='support@publicsector-excellence.com' type='email' name='email' maxLength="80" className='form__input' required />
                            </label>
                            <label className='form__label form__label-message'>
                                Message
                                <textarea placeholder='Message' name='message'  maxLength="2000" rows="6" className='form__input' required></textarea> 
                            </label>
                            <input type='submit' className='form__submit' value='send' />
                        </form>

                        <div className='contact__info'>
                            <strong>3520 LONG BEACH BLVD, SUITE 209,<br />LONG BEACH 90807</strong>
                            <p className='contact__link'>Email: <a href='mailTo:publicsectorexcellence@gmail.com' alt="Email Link publicsectorexcellence@gmail.com" target="_blank" rel="noreferrer" >publicsectorexcellence@gmail.com</a></p>
                            <p className='contact__link'>Phone: <a href='tel:5629973901' alt="Phone Link 5629973901" target="_blank" rel="noreferrer">(562) 997-3901</a></p>
                            <a href='https://www.facebook.com/Public-Sector-Excellence-608470179258336/' target="_blank" rel="noreferrer" className='contact__link contact__link--facebook'>Facebook Link</a>

                        </div>
                    </div>
                </main>
            </section>
            <Footer />
        </main>


    )
}

export default Contact