import { Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import Contact from './components/Contact'
import AboutUs from './components/AboutUs'
import PublicWorkshops from './components/PublicWorkshops'
import InHouseWorkshops from './components/InHouseWorkshops'
import OurClients from './components/OurClients'

import './App.sass';
import Error404 from './components/404'


function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/public-workshops" element={<PublicWorkshops />} />
      <Route path="/in-house-workshops" element={<InHouseWorkshops />} />
      <Route path="/our-clients" element={<OurClients />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  )
}

export default App
