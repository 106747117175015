import Header from '../Header'
import Footer from '../Footer'
import forrest from '../../assets/images/forrest-story.jpg';
import john from '../../assets/images/john-perry.png';
import bob from '../../assets/images/bob-brothers.jpg';



import './aboutus.sass'

const AboutUs = () => {
    
   
    return (

        <main className='aboutus'>

            <Header />

            <div className='container'>

                <h2 className='title'>About Us</h2>
                <div className='layout'>
                    <div className='layout__col layout__col--3'>
                        <img src={forrest} alt="Forrest Story" className='aboutus__img' />
                    </div>
                    <div className='layout__col layout__col--7'>
                        <h2 className='aboutus__title'>FORREST L. STORY, MPA</h2>
                        <p className='aboutus__pos'>PRINCIPAL</p>
                        <p>Consultant for Public Sector Excellence (PSE). Prior to founding PSE, Forrest spent over 23 years working in the public sector, 20 of which were in managerial and supervisory positions. He was the Director of Organizational Development & Training for the County of Orange, CA. In that capacity he was responsible for consulting and facilitating programs in strategic planning, team building, leadership, management and supervisory development, and organizational change. He had leadership assignments in environmental management, human resources, children's & social services, budget & management services and in organizational development. He was the first recipient of the Instructional Systems Association national award for his work in productivity improvement in local government. </p>

                        <p>As a consultant through Public Sector Excellence, he has designed and presented hundreds of workshops on the subjects of teambuilding, strategic planning, change management, conflict resolution, public sector customer service, leadership, management, supervision, working with difficult employees, presentation and facilitation skills, time management, problem solving and decision making, cost-benefit analysis, stress management, preventing harassment, achieving work life/personal balance, interview skills and job analysis. </p>

                        <p>He is often called on to give keynote presentations to civic groups, professional organizations, and to executive and management teams. Because he has “been in the trenches” as a public sector supervisor and manager, he brings a “real world” focus to his training and development programs. </p>
                    </div>
                </div>

                <div className='layout'>
                    <div className='layout__col layout__col--3'>
                        <img src={john} alt="John Perry" className='aboutus__img' />
                    </div>
                    <div className='layout__col layout__col--7'>
                        <h2 className='aboutus__title'>John Perry</h2>
                        <p className='aboutus__pos'>IN MEMORY OF</p>
                        <p>Our dear friend and partner John Perry is no longer with us, his legacy however will indelibly live within all who knew him.  If you're interested in knowing more about the organizations he founded please feel free to visit HPS or Go Far. </p>
                    </div>
                </div>



                <div className='layout'>
                    <div className='layout__col layout__col--3'>
                        <img src={bob} alt="Bob Brothers" className='aboutus__img' />
                    </div>
                    <div className='layout__col layout__col--7'>
                        <h2 className='aboutus__title'>BOB BROTHERS-  BA MANAGEMENT</h2>
                        <p className='aboutus__pos'>ASSOCIATE</p>
                        <p>Bob Brothers has over 32 years of public sector employment experience, 29 of which have been with the City of Stockton.  Throughout his career with the City, Bob has been extensively involved with the career and leadership development of the city staff.</p>

                        <p>During his last 10 years with the agency, he managed the City's training program and was an internal consultant.  Included in his responsibilities was the facilitation of several citywide training classes.   As manager of the City's training program, Bob updated, and managed the City's Supervisors' Certificate Program, Lead Workers' Certificate Program, and Office Support Series Certificate Program.  In 2002, Bob developed a program for mid-managers called, “Management and Leadership for the New Millennium.”  This program provided a communication conduit for the City Manager as well as a vehicle for the mid-managers to develop their leadership skills.</p>

                        <p>As an internal consultant, Bob provided leadership, management, and team building consulting services for many City departments.  During his final two years with the City, Mr. Brothers provided on-going facilitation to the joint city/county Library system's executive and supervisory teams as the agency transitioned to a new customer service vision and implemented an internal reorganization in order to realize this vision. </p>

                        <p>Mr. Brothers' commitment to excellence in government has been evidenced by his past involvement as a member of the Public Works Department re-engineering leadership team, and as a member of the City's Quality Council where he facilitated several process improvement teams aimed at achieving excellence in all facets of local government.  </p>

                        <p>Bob is a graduate of St. Mary's College of California, where he earned his Bachelors degree in Management.  This degree, from a school known for is progressive leadership and management programs, has provided him with a current view of business and management theory and philosophy.  This understanding of effective management and leadership earned him assignments with several City departments as an internal consultant while they underwent reorganizations and business plan development. </p>
                    </div>
                </div>



            </div>
        
            <Footer />
        </main>

    )
}

export default AboutUs