import './footer.sass'

const Footer = () => {
    return(
        <footer className='footer'>
            <div className='container'>
        
                <div className='company'>
                    <p className='company__address'>3520 LONG BEACH BLVD, SUITE 209, LONG BEACH 90807</p>
                    <p className='company__link'>Phone: <a href='tel:5629973901' alt="Phone Link 5629973901" target="_blank" rel="noreferrer">(562) 997-3901</a></p>

                    <p className='footer__disclaimer'>
                        © 1995–{new Date().getFullYear()} Public Sector Excellence All Rights Reserved. 
                    </p>
                </div>
                
          </div>
        </footer>
    )
}

export default Footer