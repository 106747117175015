import Header from '../Header'
import Footer from '../Footer'
import supervision from '../../assets/images/supervision.jpg';
import supervisionpdf from '../../assets/workshops/supervision.pdf';
import customer from '../../assets/images/customers.jpg';
import customerpdf from '../../assets/workshops/customers.pdf';
import worklife from '../../assets/images/work-life.jpg';
import worklifepdf from '../../assets/workshops/work-life.pdf';
import employeeperformance from '../../assets/images/employee-performance.jpg';
import employeeperformancepdf from '../../assets/workshops/employee-performance.pdf';
import interviewskillsjpg from '../../assets/images/interviewskills.jpg';
import interviewskillspdf from '../../assets/workshops/interviewskills.pdf';



import './publicworkshops.sass'

const PublicWorkshops = () => {

    return (

        <main className='publicworkshops'>

            <Header />


            <div className='container'>
                <h2 className='title'>Public Workshops</h2>

                <section className='workshop workshop__customer'>
                                        <div className='layout'>
                                            <div className='layout__col layout__col--7'>
                                                <h3 className='workshop__title'>Dealing Successfully with Customers</h3>
                                                {/* <small className='workshop__unavaliable'> - No Classes Avaliable - </small> */}
                                                <p className='workshop__desc'>This workshop designed for Public Sector Employees who: • Have direct contact with the public AND internal customers. • Must deal with angry, upset or highly emotional customers. • Want to learn customer service skills to build good will and a positive public image. • Want to understand their customer service role in a government environment.</p> 
                                                <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>
                                                <small> - Click a Date to Register - </small>
                                                <ul className='list'>
                                                    <li>
                                                        <a href='https://lp.constantcontactpages.com/ev/reg/ttx7a39/lp/0401e804-b011-466b-84a8-73979f8649b5' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                            <h4 className='workshop__date'>October 18, 2024</h4>
                                                            <small>8:30 AM to 12:00 Noon PT</small>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href='https://lp.constantcontactpages.com/ev/reg/tx4q78m/lp/de43f374-2e86-4c16-90cf-ffe5f16ffcc7' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                            <h4 className='workshop__date'>October 23, 2024</h4>
                                                            <small>8:30 AM to 12:00 Noon PT</small>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href='https://lp.constantcontactpages.com/ev/reg/rkm4gyv/lp/2a6a927c-4540-41d1-819f-3bc146350b3d' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                            <h4 className='workshop__date'>October 24, 2024</h4>
                                                            <small>1:00 PM to 4:30 PM PT</small>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href='https://lp.constantcontactpages.com/ev/reg/9b6rhfv/lp/9b7b21ba-b6b5-4f8c-86f1-c959f76e14fa' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                            <h4 className='workshop__date'>October 29, 2024</h4>
                                                            <small>8:30 AM to 12:00 Noon PT</small>
                                                        </a>
                                                    </li>
                                                
                                                </ul>
                                            </div>
                                            <div className='layout__col layout__col--3'>
                                                <a href={customerpdf} alt="Preparing for Supervision Workshop Overview" target="_blank" rel="noreferrer">
                                                    <img src={customer} alt="supervision workshop pdf" className='workshop__img' />
                                                </a>
                                            </div>
                                        </div>
                                    </section>



                                    <section className='workshop workshop__supervision'>
                                        <div className='layout'>
                                            <div className='layout__col layout__col--7'>
                                                <h3 className='workshop__title'>Preparing for Supervision</h3>
                                                {/* <small className='workshop__unavaliable'> - No Classes Avaliable - </small> */}
                                                <p className='workshop__desc'>Preparing for Supervision is a workshop for Public Sector employees who: * Want to promote and move up. * Are new to the job of supervision. * Have been assigned the role of lead worker. * Are thinking about moving up and taking on supervisory responsibilities. * Are experienced supervisors or managers who want to review the basics.</p> 
                                            <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>  
                                            <ul className='list'>
                                            
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/3x9mqww/lp/3057e283-0652-41f2-9a86-0fadfdba9754' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>November 6, 2024</h4>
                                                        <small>9:00 AM to 4:00 PM PT</small>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/gfdtrjd/lp/08605327-14de-4fba-a206-a35c2b6ecdee' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>November 8, 2024</h4>
                                                        <small>9:00 AM to 4:00 PM PT</small>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/w33pn5a/lp/833086ce-72fb-41eb-9aab-19aca91297ec' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>November 12, 2024</h4>
                                                        <small>9:00 AM to 4:00 PM PT</small>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/q625j9t/lp/47f397ce-2a4f-400c-935c-7465cab555c6' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>November 21, 2024</h4>
                                                        <small>9:00 AM to 4:00 PM PT</small>
                                                    </a>
                                                </li>
                                            </ul>
                                            </div>
                                            <div className='layout__col layout__col--3'>
                                                <a href={supervisionpdf} alt="Preparing for Supervision Workshop Overview" target="_blank" rel="noreferrer">
                                                    <img src={supervision} alt="supervision workshop pdf" className='workshop__img' />
                                                </a>
                                            </div>
                                        </div>
                                    </section>

               

                <div className='grid grid--col2 grid--centered grid--space'>
                           
                                <div className='grid__col'>
                                
                                <section className='workshop workshop__interviewskills'>
                                        <div className='layout'>
                                            <div className='layout__col layout__col--7'>
                                                <h3 className='workshop__title'>Interview Skills Workshop</h3>
                                                <small className='workshop__unavaliable'> - No Classes Avaliable - </small>
                                                {/* <p className='workshop__desc'>How to Deliver the Winning Interview is a workshop for Public Sector employees who want to:
                                                * Prepare thoroughly for the job interview process.
                                                * Learn techniques for presenting job qualifications in a focused and clear way.
                                                * Learn some of the don'ts and dos when responding to interview questions.
                                                * Build a career credibility profile.
                                                • Learn how to deal with difficult and unexpected questions during the interview process.
                                                • Deal with those job interview "butterflies" and get those "butterflies" to fly in formation.</p>
                                                <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>
                                                <small> - Click a Date to Register - </small> */}
                                                {/* <ul className='list'>
                                                
                                                    <li>
                                                        <a href='https://lp.constantcontactpages.com/ev/reg/wd72qh9/lp/ef6557b2-8ca3-4632-b30e-cbcb3a9420eb'  target="_blank" rel="noreferrer" className='workshop__btn'>
                                                            <h4 className='workshop__date'>July 31, 2024</h4>
                                                            <small>1:00 PM to 4:30 PM PT</small>
                                                        </a>
                                                    </li> 
                                                </ul> */}
                                            </div>
                                            <div className='layout__col layout__col--3'>
                                                <a href={interviewskillspdf} alt="Interview Skills Workshop Overview" target="_blank" rel="noreferrer">
                                                    <img src={interviewskillsjpg} alt="Improving Employee Performance workshop pdf" className='workshop__img' />
                                                </a>
                                            </div>
                                        </div>
                                    </section>

                        </div>
                        <div className='grid__col'>

                            <section className='workshop workshop__worklife'>
                                <div className='layout'>
                                    <div className='layout__col layout__col--7'>
                                        <h3 className='workshop__title'>Managing Your Time, Priorities, Work & Life Balance</h3>
                                        <small className='workshop__unavaliable'> - No Classes Avaliable - </small>
                                        {/* <p className='workshop__desc'>Managing Your Time is a workshop for Public. Sectors employees who * Want to use their time effectively. • Set priorities to achieve goals & meet multiple job demands. • Create ways tp build life balance outside of work. • Reduce external barriers in managing time and interruptions. • Reduce negative stress through work & life balance.</p>
                                        <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>
                                        <small> - Click a Date to Register - </small>
                                        <ul className='list'>

                                            <li>
                                                <a href='http://events.constantcontact.com/register/event?llr=z59hqxqab&oeidk=a07ek4tgdgcb549a92a' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                    <h4 className='workshop__date'>January 23, 2023	</h4>
                                                    <small>8:15 AM to 12:00 Noon PT</small>
                                                </a>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className='layout__col layout__col--3'>
                                        <a href={worklifepdf} alt="worklife Workshop Overview" target="_blank" rel="noreferrer">
                                            <img src={worklife} alt="worklife workshop pdf" className='workshop__img' />
                                        </a>
                                    </div>
                                </div>
                            </section>
                                    
                            </div>
                    </div>
                        <div className='grid grid--col2 grid--centered grid--space'>
                            <div className='grid__col'>
                                <section className='workshop workshop__employeeperformance'>
                                <div className='layout'>
                                    <div className='layout__col layout__col--7'>
                                        <h3 className='workshop__title'>Improving Employee Performance</h3>
                                        <small className='workshop__unavaliable'> - No Classes Avaliable - </small>
                                        {/* <p className='workshop__desc'>Improving Employee Performance is a workshop for Public. Sectors supervisors/managers who: • Communicate, problem solve & improve employee performance. • Set clear, realistic & achievable performance expectations. • Coach & inspire employees to work wth you when performance is below standard. • Prepare for the annual performance appraisal.</p>
                                        <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>
                                        <small> - Click a Date to Register - </small>
                                        <ul className='list'>
                                        
                                            <li>
                                                <a href='http://events.constantcontact.com/register/event?llr=z59hqxqab&oeidk=a07ek7tsdiw3258dbc1'  target="_blank" rel="noreferrer" className='workshop__btn'>
                                                    <h4 className='workshop__date'>March 15, 2024</h4>
                                                    <small>8:15 AM to 12:00 PM PT</small>
                                                </a>
                                            </li> 
                                        </ul> */}
                                    </div>
                                    <div className='layout__col layout__col--3'>
                                        <a href={employeeperformancepdf} alt="Improving Employee Performance Workshop Overview" target="_blank" rel="noreferrer">
                                            <img src={employeeperformance} alt="Improving Employee Performance workshop pdf" className='workshop__img' />
                                        </a>
                                    </div>
                                </div>
                            </section>

                            </div>
                            <div className='grid__col'>                               </div>
                        </div>
                        {/* <div className='grid grid--col2 grid--centered grid--space'>
                            <div className='grid__col'>
                           
                                 
                            </div>
                            <div className='grid__col'>
                            </div>
                        </div>
                 */}

            </div>

            <Footer />
        </main>

    )
}

export default PublicWorkshops