import { NavLink } from 'react-router-dom'
import { useState } from "react"
import './header.sass'
import logo from '../../assets/images/logo.png';

const Header = () => {
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
      };
    return(
        <header className='header'>
            <NavLink exact='true' className='logo' to='/'>
                <img src={logo} alt="PUBLIC SECTOR EXCELLENCE" /> Public Sector Excellence
            </NavLink>
            <nav className={isActive ? "nav" : "nav nav--active"}>
                <div className='nav__container'>
                    <button type='button' className={isActive ? "nav__mobile nav__mobile-toggle" : "nav__mobile nav__mobile-toggle nav__mobile--active"} onClick={handleToggle}>
                        <span className='sr-only'>Toggle navigation</span>
                        <span className='nav__mobile-icon'></span>
                        <span className='nav__mobile-icon'></span>
                        <span className='nav__mobile-icon'></span>
                    </button>
            
                    <ul className={isActive ? "nav__list" : "nav__list nav__list--active"}>
                        <li className='nav__list-item nav__list-item--dropdown'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link' to='/about-us'>About Us</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link' to='/public-workshops'>Public Workshops</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link' to='/in-house-workshops'>In-House Workshops</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link' to='/our-clients'>Our Clients</NavLink>
                        </li>
                        <li className='nav__list-item'>
                            <NavLink exact='true' activeclassname='nav__link--active' className='nav__link' to='/contact'>Contact</NavLink>
                        </li>
                    </ul>
                </div>		
            </nav>
          

        </header>
    )
}

export default Header